import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import {
  AdapterLuxon,
  Alert,
  Button,
  Checkbox,
  DatePicker,
  FormControlLabel,
  FormGroup,
  IconButton,
  SelectionButton,
  SingleSelectionButtons,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from '@octanner/prism-core'
import { YbProgramYearbookLocalesInput } from '../common/models/YbProgramYearbookLocales'
import { DateTime } from 'luxon'
import React, { useState } from 'react'
import 'regenerator-runtime/runtime'
import { ActionsContainer, Form, Row } from '../common/components/Styled'
import {
  CelebrationFrequency,
  ProgramRepresentation,
} from '../common/models/ProgramRepresentation'
import { YbProgramInput } from '../common/models/YbProgramInput'
import { useGetLanguages } from '../hooks/useGetLanguages'
import { FormValue } from '../models/CelebrationGroupEdit'
import { Library } from '@octanner/prism-icons'
import { tannerGray } from '@octanner/prism-core/ThemeProvider/colors'
import { LaunchDarklyFlag } from './MainSettings'

const LanguageContainer = styled(FormGroup)`
  display: grid;
  grid-auto-flow: column;
  grid-gap: ${({ theme }) => theme.spacing(3)};
`

export type OnSubmitForm = Omit<YbProgramInput, 'id' | 'programStatus'> &
  Omit<YbProgramYearbookLocalesInput, 'programId'>

interface Props {
  onCancel: () => void
  onSubmit: (input: OnSubmitForm) => void
  program?: ProgramRepresentation
  launchDarklyFlags?: LaunchDarklyFlag[]
}

type MonthType = 'previous' | 'same' | 'after'

interface State {
  celebrationFrequency: CelebrationFrequency
  batchDay: number | undefined
  catchUpDate: FormValue<DateTime | undefined>
  anniversaryStartDate: FormValue<DateTime | undefined>
  month: MonthType
  annualStart: FormValue<DateTime | undefined>
  annualDate: FormValue<DateTime | undefined>
  languages: string[]
  victoriesProgramId: string
}

interface ErrorState {
  key: keyof State
  message: string
}

const initializeDate = (
  value?: string | DateTime
): FormValue<DateTime | undefined> => {
  const date = value
    ? typeof value === 'string'
      ? DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT)
      : value
    : undefined
  return { value: date }
}
const getAnnualTimestamp = (
  program?: ProgramRepresentation
): DateTime | undefined => {
  if (program?.celebrationFrequency !== 'ANNUALLY') return
  if (!program.batchMonth || !program.batchDay) return

  const date = DateTime.local()
  date.set({ month: program.batchMonth, day: program.batchDay })

  return date
}

const initializeState = (program?: ProgramRepresentation): State => ({
  celebrationFrequency: program?.celebrationFrequency,
  month: 'previous',
  annualStart: initializeDate(getAnnualTimestamp(program)),
  catchUpDate: initializeDate(program?.catchupTimestamp),
  anniversaryStartDate: initializeDate(program?.anniversaryStartDate),
  annualDate: initializeDate(program?.annualCelebrationTimestamp),
  batchDay: program?.batchDay ?? null,
  languages: program?.yearbookLocales || [],
  victoriesProgramId: program?.victoriesProgram?.id || '',
})

const getBatchMonth = ({
  celebrationFrequency,
  annualStart,
}: State): number | undefined => {
  if (celebrationFrequency !== 'ANNUALLY') return
  if (annualStart.value?.invalid) return

  return annualStart.value.month
}

const getAnnualCelebrationTimeStamp = ({
  celebrationFrequency,
  annualDate,
}: State): string | undefined => {
  if (celebrationFrequency !== 'ANNUALLY') return
  if (!annualDate.value?.isValid) return

  return annualDate.value.toISO()
}

const getCatchupDate = ({ catchUpDate }: State): string | undefined => {
  if (!catchUpDate.value?.isValid) return

  return catchUpDate.value.toISO()
}

const getAnniversaryStartDate = ({
  anniversaryStartDate,
}: State): string | undefined => {
  if (!anniversaryStartDate.value?.isValid) return

  return anniversaryStartDate.value.toISODate()
}

function* validate(state: State): Generator<ErrorState> {
  if (!state.languages.length) {
    yield {
      key: 'languages',
      message: 'Language selection is required.',
    }
  }
  if (state.anniversaryStartDate.value?.invalid) {
    yield {
      key: 'anniversaryStartDate',
      message: 'This field is required.',
    }
  }
}

type DateKey =
  | 'catchUpDate'
  | 'anniversaryStartDate'
  | 'annualStart'
  | 'annualDate'

export default function GeneralProgramSettingsEdit({
  onCancel,
  program,
  onSubmit,
  launchDarklyFlags,
}: Props): JSX.Element {
  const languageList = useGetLanguages()
  const [state, setState] = useState(() => initializeState(program))
  const [yearbookType, setYearbookType] = useState<'standard' | 'custom'>(
    program?.customYearbook ? 'custom' : 'standard'
  )
  const [errors, setErrors] = useState<ErrorState[]>([])
  const { catchUpDate, anniversaryStartDate, languages, victoriesProgramId } =
    state
  const numberOfRows = Math.ceil(languageList.length / 3)
  const languageError = errors.find((e) => e.key === 'languages')

  const handleDateChange = (key: DateKey) => (date: DateTime) => {
    setState((prevState) => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        value: date,
      },
    }))
  }

  const handleLanguageChange = (language: string, checked: boolean) => {
    if (!checked) {
      setState((prevState) => ({
        ...prevState,
        languages: prevState.languages.filter((lan) => lan !== language),
      }))
      return
    }

    setState((prevState) => ({
      ...prevState,
      languages: [...prevState.languages, language],
    }))
  }

  const handleBatchFrequencyChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value as CelebrationFrequency
    setState((prevState) => ({
      ...prevState,
      celebrationFrequency: value,
      batchDay: value === 'DAILY_BATCH_MONTHLY' ? -14 : null,
    }))
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const errors = Array.from(validate(state))
    if (errors.length) {
      setErrors(errors)
      return
    }
    const victoriesProgramId = parseInt(state.victoriesProgramId)
    onSubmit({
      celebrationFrequency: state.celebrationFrequency,
      batchMonth: getBatchMonth(state),
      annualCelebrationTimestamp: getAnnualCelebrationTimeStamp(state),
      batchDay: state.batchDay,
      catchupTimestamp: getCatchupDate(state),
      anniversaryStartDate: getAnniversaryStartDate(state),
      yearbookLocales: languages,
      victoriesProgramId: victoriesProgramId || undefined,
      customYearbook: yearbookType === 'custom',
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <section>
        <Typography variant="h3" data-testid="yps:general-edit:title">
          Program Settings
        </Typography>
      </section>
      <Row>
        <label htmlFor="recognition-program-id">
          <Typography>Recognition Program ID</Typography>
        </label>
        <TextField
          id="recognition-program-id"
          label="Recognition Program ID"
          value={victoriesProgramId}
          helperText="Recognition Program is required for points for service"
          style={{ width: 456 }}
          inputProps={{
            'data-testid': 'yps:general-edit:recognition-program-id',
          }}
          onChange={(event) =>
            setState((cur) => ({
              ...cur,
              victoriesProgramId: event.target.value,
            }))
          }
        />
      </Row>
      <Row>
        <label htmlFor="anniversary-start-date">
          <Typography>Anniversary Start Date</Typography>
        </label>
        <DatePicker
          label="Anniversary Start Date"
          dateAdapter={AdapterLuxon}
          disableMaskedInput
          renderInput={(params) => (
            <TextField
              {...params}
              id="anniversary-start-date"
              style={{ width: 200 }}
              error={Boolean(
                anniversaryStartDate.errors ||
                  errors.find((e) => e.key === 'anniversaryStartDate')
              )}
              helperText={
                errors.find((e) => e.key === 'anniversaryStartDate')?.message ??
                'Earliest anniversary date that will have a celebration created by our system'
              }
              inputProps={{
                ...params.inputProps,
                'data-testid': 'yps:general-edit:anniversary-start-date',
              }}
            />
          )}
          value={anniversaryStartDate.value ?? ''}
          onChange={handleDateChange('anniversaryStartDate')}
        />
      </Row>
      <Row>
        <Typography>Auto-ship orders placement date</Typography>
        <RadioGroup
          aria-label="auto-shipBatching"
          name="auto-shipBatching"
          value={state.celebrationFrequency}
          onChange={handleBatchFrequencyChange}
        >
          <FormControlLabel
            value="DAILY"
            control={<Radio />}
            label="By anniversary"
          />
          <FormControlLabel
            value="DAILY_BATCH_MONTHLY"
            control={<Radio />}
            label="Batched monthly"
          />
          <Typography variant="body2" style={{ color: tannerGray[500] }}>
            {state.celebrationFrequency == 'DAILY'
              ? 'Orders are placed for each anniversary as scheduled in the Celebration group'
              : 'Orders for all anniversaries occurring in a calendar month are placed at the same time'}
          </Typography>
        </RadioGroup>
      </Row>
      <Row>
        <label htmlFor="catch-up-date">
          <Typography>Catch Up Date</Typography>
        </label>
        <DatePicker
          label="Catch Up Date"
          disableMaskedInput
          dateAdapter={AdapterLuxon}
          renderInput={(params) => (
            <TextField
              {...params}
              id="catch-up-date"
              style={{ width: 200 }}
              error={Boolean(catchUpDate.errors)}
              helperText={catchUpDate.errors}
              inputProps={{
                ...params.inputProps,
                'data-testid': 'yps:general-edit:catch-up-date',
              }}
            />
          )}
          value={catchUpDate.value ?? ''}
          onChange={handleDateChange('catchUpDate')}
        />
      </Row>
      {launchDarklyFlags?.some(
        (flag) => flag.key === 'ccAdminCcybYearbookType2D250121' && flag.value
      ) && (
        <>
          <Row>
            <Typography>Yearbook Type</Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: 2,
              }}
            >
              <Box>
                <SingleSelectionButtons
                  data-testid="yps:general-edit:yearbook-type"
                  value={yearbookType}
                  onClick={(event) => {
                    event.preventDefault()
                    event.stopPropagation()
                    setYearbookType(
                      (event.target as HTMLInputElement).value as
                        | 'standard'
                        | 'custom'
                    )
                  }}
                >
                  <SelectionButton value="standard">Standard</SelectionButton>
                  <SelectionButton value="custom">Custom</SelectionButton>
                </SingleSelectionButtons>
              </Box>
              {yearbookType === 'standard' && (
                <IconButton
                  data-testid="yps:general-edit:edit-standard-yearbook"
                  aria-label=""
                  sx={{ marginRight: '8px' }}
                  onClick={() =>
                    window.open(
                      `/library/standard-yearbook-builder/customer/${program.coreProgram.customer.id}/program/${program.id}`,
                      '_blank'
                    )
                  }
                >
                  <Tooltip title="Edit Standard Yearbook" placement="top">
                    <Library />
                  </Tooltip>
                </IconButton>
              )}
            </Box>
          </Row>
        </>
      )}
      <Row>
        <Typography>Yearbook Language(s)</Typography>
        <Box>
          {languageError && (
            <Alert color="error" data-testid="yps:general-edit:language-error">
              {languageError.message}
            </Alert>
          )}
          <LanguageContainer
            style={{ gridTemplateRows: `repeat(${numberOfRows}, 1fr)` }}
          >
            {languageList.map((locale) => (
              <FormControlLabel
                key={locale}
                control={
                  <Checkbox
                    checked={languages.includes(locale)}
                    onChange={(e) =>
                      handleLanguageChange(locale, e.target.checked)
                    }
                    name={locale}
                    inputProps={{
                      // @ts-ignore actual prop on checkbox
                      'data-testid': `yps:general-edit:language:${locale}`,
                    }}
                  />
                }
                label={locale}
              />
            ))}
          </LanguageContainer>
        </Box>
      </Row>
      <Row>
        <ActionsContainer>
          <Button
            onClick={onCancel}
            color="inherit"
            data-testid="yps:general-edit:cancel"
          >
            Cancel
          </Button>
          <Button type="submit" data-testid="yps:general-edit:submit">
            Save Settings
          </Button>
        </ActionsContainer>
      </Row>
    </Form>
  )
}
