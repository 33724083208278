import {
  BillToLocationType,
  ShipToAddressType,
  ShipToAttentionType,
} from '../common/models/CelebrationLevelGroupConfig'
import { YbCelebrationLevelGroupConfigInput } from '../common/models/YbCelebrationLevelGroupConfigInput'
import { ContextData } from './GroupSettingsContext'

export interface FormValue<TValue> {
  value: TValue
  errors?: string[]
}

export type TextFormValue = FormValue<string>

export interface IdentityBase {
  id: string
  firstName?: string
  lastName?: string
  preferredName?: string
  email?: string
}

export interface IdentityWithErrors extends IdentityBase {
  errors?: string[]
}

export const attentionOptions: { text: string; type: ShipToAttentionType }[] = [
  { text: 'The celebrant', type: 'CELEBRANT' },
  { text: 'The manager of the celebrant', type: 'MANAGER' },
  { text: 'Customer Provided', type: 'CUSTOMER_PROVIDED' },
]

export const addressOptions: { text: string; type: ShipToAddressType }[] = [
  { text: 'Celebrant home', type: 'HOME' },
  { text: 'Work 1', type: 'WORK_1' },
  { text: 'Work 2', type: 'WORK_2' },
]

export const billingLocationOptions: {
  text: string
  type: BillToLocationType
}[] = [
  { text: 'Billing location 1', type: 'BILL_1' },
  { text: 'Billing location 2', type: 'BILL_2' },
]

export const stateToInput = (
  cs: ContextData
): YbCelebrationLevelGroupConfigInput => {
  if (!cs?.milestone) {
    return {
      name: cs?.name,
      milestone: cs?.milestone,
      celebrationLevelConfigs: cs?.selectedYears?.map((year) => ({
        name: year,
        rank: Number(year),
      })),
      celebrantCongratulatoryNotificationEnabled:
        cs?.celebrantCongratulatoryNotificationEnabled,
      peersAnniversaryDayNotificationEnabled:
        cs?.peersAnniversaryDayNotificationEnabled,
      managerAnniversaryDayNotificationEnabled:
        cs?.managerAnniversaryDayNotificationEnabled,
    }
  }
  return {
    name: cs?.name,
    milestone: cs?.milestone,
    surveyUrl: cs?.surveyEnabled ? cs?.surveyUrl : undefined,
    celebrationLevelConfigs: cs?.selectedYears?.map((year) => ({
      name: year,
      rank: Number(year),
    })),
    surveyEnabled: cs?.surveyEnabled,
    autoshipAwards: cs?.autoshipAwards,
    printCoverType: cs?.printCoverType,
    yearbookEnabled: cs?.yearbookEnabled,
    peerNotesEnabled: cs?.peerNotesEnabled,
    peerPhotoEnabled: cs?.peerPhotoEnabled,
    peerVideoEnabled: cs?.peerVideoEnabled,
    shipToAddressType: cs?.shipToAddressType,
    accrualPointsForServiceEnabled: cs?.accrualPointsForServiceEnabled,
    userSelectedOrder: cs?.userSelectedOrder,
    leaderNotesEnabled: cs?.leaderNotesEnabled,
    leaderPhotoEnabled: cs?.leaderPhotoEnabled,
    leaderVideoEnabled: cs?.leaderVideoEnabled,
    shipToAttentionType: cs?.shipToAttentionType,
    billToLocationType: cs?.billToLocationType,
    printYearbookEnabled: cs?.printYearbookEnabled,
    printCertificateEnabled: cs?.printCertificateEnabled,
    emptyYearbookEmailRecipient:
      cs?.yearbookEnabled && cs?.emptyYearbookEmailEnabled
        ? cs?.emptyYearbookEmailRecipientIdentity.id
        : undefined,
    emptyYearbookEmailEnabled: cs?.yearbookEnabled
      ? cs?.emptyYearbookEmailEnabled
      : false,
    presentationReminderEmail: cs?.presentationReminderEmail,
    celebrantCongratulatoryNotificationEnabled:
      cs?.celebrantCongratulatoryNotificationEnabled,
    celebrantInvitePeersNotificationEnabled:
      cs?.celebrantInvitePeersNotificationEnabled,
    peersAnniversaryDayNotificationEnabled:
      cs?.peersAnniversaryDayNotificationEnabled,
    celebrantCertificateNotificationEnabled:
      cs?.celebrantCertificateNotificationEnabled,
    managerCertificateNotificationEnabled:
      cs?.managerCertificateNotificationEnabled,
  }
}
