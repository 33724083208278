import { styled } from '@mui/material/styles'
import { Alert, Box, Button, Typography } from '@octanner/prism-core'
import React, { useEffect, useMemo, useState, useRef } from 'react'
import colors from '../common/colors'
import { YbCelebrationLevelGroupConfigInput } from '../common/models/YbCelebrationLevelGroupConfigInput'
import {
  useGroupSettings,
  useGroupSettingsDispatch,
} from '../contexts/GroupSettingsContext'
import { stateToInput } from '../models/CelebrationGroupEdit'
import AnniversaryYears from './AnniversaryYears'
import Loading from './Loading'
import NameAndType from './NameAndType'
import Yearbooks from './Yearbooks'
import { ProgramRepresentation } from '../common/models/ProgramRepresentation'
import CelebrationGroupActionScheduleContainer from './CelebrationGroupSchedule/CelebrationGroupActionScheduleContainer'
import useDeleteActionScheduleTemplate from '../hooks/useDeleteActionScheduleTemplate'
import ConfirmationModal from './ConfirmationModal'
export interface TemplateUUIDs {
  peerNotes: string[]
  leaderNotes: string[]
  yearbook: string[]
}
interface Props {
  program: ProgramRepresentation
  showLaunchDarkly?: boolean
  onSubmit: (input: YbCelebrationLevelGroupConfigInput) => void
  onCancel: () => void
  noRecogId: boolean
  loading: boolean
}

const Root = styled('form')`
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing(5)};
  > section + section {
    padding-top: ${({ theme }) => theme.spacing(5)};
    border-top: 1px solid ${colors.gray400};
  }
`

export const GroupSettingsTitle = styled(Typography)`
  padding: ${({ theme }) => theme.spacing(5)} 0;
  border-top: 1px solid ${colors.gray400};
  border-bottom: 1px solid ${colors.gray400};
`

export default function CelebrationGroupEdit({
  program,
  showLaunchDarkly,
  onSubmit,
  onCancel,
  noRecogId,
  loading,
}: Props): JSX.Element {
  const [astsToPossiblyDelete, setAstsToPossiblyDelete] =
    useState<TemplateUUIDs>({
      peerNotes: [],
      leaderNotes: [],
      yearbook: [],
    })

  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [templatesToDelete, setTemplatesToDelete] = useState<string[]>([])
  const dispatch = useGroupSettingsDispatch()
  const groupSettingsContext = useGroupSettings()
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const isEditMode = useMemo(
    () => !!groupSettingsContext?.id,
    [groupSettingsContext?.id]
  )
  const previousSettingsRef = useRef({
    peerNotesEnabled: groupSettingsContext?.peerNotesEnabled,
    leaderNotesEnabled: groupSettingsContext?.leaderNotesEnabled,
    yearbookEnabled: groupSettingsContext?.yearbookEnabled,
  })

  const { deleteActionScheduleTemplate } = useDeleteActionScheduleTemplate()

  const handleDelete = async () => {
    await deleteActionScheduleTemplate({
      input: {
        actionScheduleTemplateUuids: templatesToDelete,
      },
      celebrationLevelGroupUuid: groupSettingsContext?.id,
      onSuccess: () => {
        onSubmit(stateToInput(groupSettingsContext))
      },
    })
  }

  useEffect(() => {
    if (noRecogId) {
      setShowAlert(true)
    }
  }, [noRecogId])

  useEffect(() => {
    const previousSettings = previousSettingsRef.current

    const updateTemplatesToDelete = (condition, templates) => {
      setTemplatesToDelete((prev) => {
        const newTemplates = condition
          ? [...prev, ...templates]
          : prev.filter((uuid) => !templates.includes(uuid))
        return Array.from(new Set(newTemplates)) // Ensure no duplicates
      })
    }

    updateTemplatesToDelete(
      !groupSettingsContext?.peerNotesEnabled &&
        previousSettings.peerNotesEnabled,
      astsToPossiblyDelete.peerNotes
    )

    updateTemplatesToDelete(
      !groupSettingsContext?.leaderNotesEnabled &&
        previousSettings.leaderNotesEnabled,
      astsToPossiblyDelete.leaderNotes
    )

    updateTemplatesToDelete(
      !groupSettingsContext?.yearbookEnabled &&
        previousSettings.yearbookEnabled,
      astsToPossiblyDelete.yearbook
    )

    if (
      groupSettingsContext?.yearbookEnabled &&
      previousSettings.yearbookEnabled
    ) {
      setTemplatesToDelete((prev) => {
        const filteredTemplates = prev.filter(
          (template) => !astsToPossiblyDelete.yearbook.includes(template)
        )

        // Add back in leaderNotes and peerNotes if they are unchecked if they exist in astsToPossiblyDelete
        const newTemplates = [
          ...filteredTemplates,
          ...(groupSettingsContext?.leaderNotesEnabled
            ? []
            : astsToPossiblyDelete.leaderNotes),
          ...(groupSettingsContext?.peerNotesEnabled
            ? []
            : astsToPossiblyDelete.peerNotes),
        ]
        return Array.from(new Set(newTemplates)) // Ensure no duplicates
      })
    }
  }, [groupSettingsContext, astsToPossiblyDelete])
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!groupSettingsContext?.name?.trim().length) {
      dispatch({ type: 'setGroupName', name: '' })
      dispatch({
        type: 'setGroupNameErrors',
        groupNameErrors: ['Please enter a name'],
      })

      return
    } else if (templatesToDelete.length > 0) {
      setModalOpen(true)
    } else {
      onSubmit(stateToInput(groupSettingsContext))
    }
  }

  const customerName = program?.coreProgram?.customer?.name ?? ''
  const programId = program?.id ?? ''
  const customerId = program?.coreProgram?.customer?.id ?? ''
  const celebrationFrequency = program?.celebrationFrequency ?? 'DAILY'
  const batchDay = program?.batchDay ?? null

  return (
    <Root onSubmit={handleSubmit}>
      {loading && !noRecogId ? (
        <Loading />
      ) : (
        <>
          <Typography variant="h2">Celebration Group</Typography>
          <NameAndType />
          <AnniversaryYears />
          {groupSettingsContext?.milestone ? (
            <>
              <Yearbooks />
            </>
          ) : null}

          {isEditMode && (
            <section
              style={{
                paddingTop: '16px',
                borderTop: `1px solid ${colors.gray400}`,
              }}
            >
              <Button
                onClick={() =>
                  window.open(
                    `https://octanner.atlassian.net/secure/CreateIssueDetails!init.jspa?pid=10130&issuetype=10007&summary=Program+config+changes+requested+for+${customerName}&description=h3.+Client+details%0D%0A%2A+Client+name%3A+${customerName}%0D%0A%2A+Customer+UUID%3A+${customerId}%0D%0A%2A+Program+UUID%3A+${programId}%0D%0A%2A+Celebration+Level+Group+UUID%3A+${groupSettingsContext?.id}%0D%0A%0D%0Ah3.+Changes+needed%0D%0A%2A+%0D%0A%0D%0Ah3.+Date+needed+by%0D%0A%2A+&components=10847&priority=10009&parent=YBC-903&labels=CPTRequest`,
                    '_blank'
                  )
                }
                color="secondary"
              >
                Request change
              </Button>
            </section>
          )}

          <section
            style={{
              marginBottom: 32,
            }}
          >
            {showLaunchDarkly && (
              <Box>
                {alert && (
                  <ConfirmationModal
                    open={modalOpen}
                    titleText="WARNING"
                    dataTestId="auto-delete-confirmation-modal"
                    messageText="Removing peer notes, leader notes, or yearbooks will delete all associated action schedules. Are you sure you want to proceed?"
                    primaryButtonText="Proceed"
                    secondaryButtonText="Cancel"
                    onModalClose={() => setModalOpen(false)}
                    onModalAction={handleDelete}
                  />
                )}
                <CelebrationGroupActionScheduleContainer
                  displayHeading
                  celebrationLevelGroupUuid={groupSettingsContext?.id}
                  programId={programId}
                  setAstsToPossiblyDelete={setAstsToPossiblyDelete}
                  celebrationFrequency={celebrationFrequency}
                  batchDay={batchDay}
                />
              </Box>
            )}
          </section>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '8px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
              }}
            >
              <Button data-testid="anniversary-gift:save" type="submit">
                Save group
              </Button>
              <Button color="inherit" onClick={onCancel}>
                Cancel
              </Button>
            </Box>
            {showAlert && (
              <Alert onClose={() => setShowAlert(false)} color="error">
                You must add a Recognition Program ID in order to use points for
                service. Please go back to the main settings page to add an ID
                and try again.
              </Alert>
            )}
          </Box>
        </>
      )}
    </Root>
  )
}
