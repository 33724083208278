import { styled } from '@mui/material/styles'
import { CelebrationGroupSettings } from '@octanner/yearbook-program-settings'
import React from 'react'
import { BrowserRouter, Link, Route, Switch } from 'react-router-dom'
import useEnsureLoggedIn from './auth/useEnsureLoggedIn'
import BudgetGroupWrapper from './components/BudgetGroupWrapper'
import CelebrationSettingsWrapper from './components/CelebrationSettingsWrapper'
import Loading from './components/Loading'
import ProgramSettingsWrapper from './components/ProgramSettingsWrapper'
import AppProviders from './utilities/AppProviders'
import ScrollToTop from './utilities/ScrollToTop'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

const Root = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(2),
  maxWidth: 1200,
  margin: `${theme.spacing(2)} auto`,
  padding: `0 ${theme.spacing(2)}`,
}))

const Nav = styled('nav')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}))

function App() {
  i18n.use(initReactI18next).init({
    lng: 'en',
    resources: {},
    interpolation: {
      prefix: '{',
      suffix: '}',
      escapeValue: false,
    },
  })
  const state = useEnsureLoggedIn()
  if (state !== 'success') return <Loading />
  return (
    <Root>
      <AppProviders>
        <BrowserRouter>
          <ScrollToTop />
          <Nav>
            <Link to="/program-settings">Program Settings</Link>
            <Link to="/celebration-settings">Celebration Settings</Link>
            <Link to="/budget-settings">Budget Settings</Link>
          </Nav>
          <Switch>
            <Route
              path="/program-settings/:programId"
              render={({ match }) => (
                <ProgramSettingsWrapper programId={match.params.programId} />
              )}
            />
            <Route path="/program-settings">
              <ProgramSettingsWrapper />
            </Route>
            <Route
              exact
              path="/celebration-settings/new/:programId/"
              render={({ match, history }) => (
                <CelebrationGroupSettings
                  programId={match.params.programId}
                  onChange={() => {
                    history.push(`/program-settings/${match.params.programId}`)
                  }}
                />
              )}
            />
            <Route
              exact
              path="/celebration-settings/:programId/:celebrationGroupId"
              render={({ match }) => (
                <CelebrationSettingsWrapper
                  programId={match.params.programId}
                  celebrationGroupId={match.params.celebrationGroupId}
                />
              )}
            />
            <Route path="/celebration-settings">
              <CelebrationSettingsWrapper />
            </Route>
            <Route
              path="/budget-settings/new/:programId/"
              render={({ match }) => (
                <BudgetGroupWrapper programId={match.params.programId} isNew />
              )}
            />
            <Route
              path="/budget-settings/:programId/:budgetGroupId"
              render={({ match }) => (
                <BudgetGroupWrapper
                  programId={match.params.programId}
                  budgetGroupId={match.params.budgetGroupId}
                />
              )}
            />
            <Route path="/budget-settings">
              <BudgetGroupWrapper />
            </Route>
          </Switch>
        </BrowserRouter>
      </AppProviders>
    </Root>
  )
}

export default App
