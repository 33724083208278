import { Button, Typography } from '@octanner/prism-core'
import { DateTime } from 'luxon'
import React from 'react'
import {
  ContentContainer,
  HeaderContainer,
  Label,
} from '../common/components/Styled'
import { ProgramRepresentation } from '../common/models/ProgramRepresentation'
import { LaunchDarklyFlag } from './MainSettings'

interface Props {
  program?: ProgramRepresentation
  onEditClick: () => void
  hideEdit?: boolean
  launchDarklyFlags?: LaunchDarklyFlag[]
}

export default function GeneralProgramSettings({
  program,
  onEditClick,
  hideEdit,
  launchDarklyFlags,
}: Props): JSX.Element {
  return (
    <section data-testid="mainSettings:programSettings:container">
      <HeaderContainer>
        <Typography variant="h3" data-testid="program:view:title">
          Program Settings
        </Typography>
        {!hideEdit && (
          <Button
            data-testid="program:view:edit"
            variant="text"
            onClick={onEditClick}
          >
            Edit
          </Button>
        )}
      </HeaderContainer>
      <ContentContainer>
        <Label>Recognition Program ID</Label>
        <Typography>{program?.victoriesProgram?.id}</Typography>
        <Label data-testid="mainSettings:programSettings:anniversaryStartDate:label">
          Anniversary Start Date
        </Label>
        <Typography data-testid="mainSettings:programSettings:anniversaryStartDate:value">
          {' '}
          {program?.anniversaryStartDate &&
            DateTime.fromISO(program.anniversaryStartDate).toLocaleString(
              DateTime.DATE_MED
            )}
        </Typography>
        <Label>Auto-ship orders placement date</Label>
        <Typography>
          {program?.celebrationFrequency === 'DAILY_BATCH_MONTHLY'
            ? 'Batched monthly'
            : 'By anniversary'}
        </Typography>
        <Label data-testid="mainSettings:programSettings:catchUpDate:label">
          Catch-Up Date
        </Label>
        <Typography data-testid="mainSettings:programSettings:catchUpDate:value">
          {program?.catchupTimestamp &&
            DateTime.fromISO(program.catchupTimestamp).toLocaleString(
              DateTime.DATE_MED
            )}
        </Typography>
        <Label data-testid="mainSettings:programSettings:yearbookLanguages:label">
          Yearbook Language(s)
        </Label>
        <Typography data-testid="mainSettings:programSettings:yearbookLanguages:value">
          {program?.yearbookLocales?.join(', ')}
        </Typography>
        {launchDarklyFlags?.some(
          (flag) => flag.key === 'ccAdminCcybYearbookType2D250121' && flag.value
        ) && (
          <>
            <Label data-testid="mainSettings:programSettings:yearbookType:label">
              Yearbook Type
            </Label>
            <Typography data-testid="mainSettings:programSettings:yearbookType:value">
              {program?.customYearbook ? 'Custom' : 'Standard'}
            </Typography>
          </>
        )}
      </ContentContainer>
    </section>
  )
}
