import React, { useState, useEffect, useMemo } from 'react'
import {
  Alert,
  Box,
  FormControl,
  FormGroup,
  Typography,
} from '@octanner/prism-core'
import { useForm } from 'react-hook-form'
import { ApolloError } from '@apollo/client'
import { EventOption } from './utils'
import ActionFormMessages from './ActionFormMessage'
import ShippingInputs from './ShippingInputs'
import SurveyUrlInput from './SurveyUrlInput'
import ActionButtons from './ActionButtons'
import ScheduleInputs from './ScheduleInputs'
import ActionInput from './ActionInput'
import {
  TaskScheduleGroupCodeInput,
  YbActionScheduleTemplateRepresentation,
  YbCreateActionScheduleTemplateInput,
  YbUpdateActionScheduleTemplateInput,
} from '../../models/ActionScheduleTemplate'
import { useGroupSettings } from '../../contexts/GroupSettingsContext'
import { useGetAllTaskScheduleGroups } from '../../hooks/useGetAllTaskScheduleGroups'
import {
  BillToLocationType,
  ShipToAddressType,
  ShipToAttentionType,
} from '../../common/models/CelebrationLevelGroupConfig'
import { CelebrationFrequency } from '../../common/models/ProgramRepresentation'

type CommonActionScheduleFormFields = {
  when: 'before' | 'after'
  surveyUrl: string
  shippingAddress: ShipToAddressType
  attentionTo: ShipToAttentionType
  billingLocation: BillToLocationType
}

export type FormData = Omit<
  YbCreateActionScheduleTemplateInput,
  'taskScheduleGroup'
> &
  CommonActionScheduleFormFields & {
    taskScheduleGroup: EventOption
  }

export type EditFormData = Pick<
  YbUpdateActionScheduleTemplateInput,
  'offsetFrom' | 'startOffset' | 'addInternationalShippingOffset'
> &
  CommonActionScheduleFormFields

interface Props {
  actionScheduleTemplates: YbActionScheduleTemplateRepresentation[]
  editingTemplate?: YbActionScheduleTemplateRepresentation | null
  error: ApolloError
  loading: boolean
  onSave?: (data: FormData) => void
  onEdit?: (
    data: EditFormData,
    existingTemplate: YbActionScheduleTemplateRepresentation
  ) => void
  onCancel: () => void
  celebrationFrequency?: CelebrationFrequency
  batchDay?: number
}

const ActionForm = ({
  actionScheduleTemplates,
  editingTemplate,
  error,
  loading,
  onSave,
  onEdit,
  onCancel,
  celebrationFrequency,
  batchDay,
}: Props) => {
  const [lockInputs, setLockInputs] = useState({
    when: false,
    offsetFrom: false,
    startOffset: false,
  })
  const groupSettingsContext = useGroupSettings()
  const [formAlert, setFormAlert] = useState({
    isOpen: !!error,
    type: error ? 'error' : 'success',
    message: '',
  })
  const [eventOptions, setEventOptions] = useState<EventOption[]>([])
  const { taskScheduleGroups } = useGetAllTaskScheduleGroups()

  useEffect(() => {
    if (taskScheduleGroups) {
      setEventOptions(
        taskScheduleGroups.map(
          (taskScheduleGroup): EventOption => ({
            id: Number(taskScheduleGroup.id),
            label: taskScheduleGroup.description,
            value: taskScheduleGroup.code,
          })
        )
      )
    }
  }, [taskScheduleGroups])

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      taskScheduleGroup: editingTemplate
        ? {
            id: editingTemplate.taskScheduleGroup.id,
            label: editingTemplate.taskScheduleGroup.description,
            value: editingTemplate.taskScheduleGroup.code,
          }
        : {
            id: 0,
            label: '',
            value: '',
          },
      startOffset: editingTemplate ? Math.abs(editingTemplate.startOffset) : 0,
      when:
        editingTemplate && editingTemplate.startOffset > 0 ? 'after' : 'before',
      offsetFrom: editingTemplate
        ? editingTemplate.offsetFrom
        : 'CELEBRATION_DATE',
      addInternationalShippingOffset: false,
      shippingAddress: groupSettingsContext?.shipToAddressType ?? 'WORK_1',
      attentionTo: groupSettingsContext?.shipToAttentionType ?? 'MANAGER',
      billingLocation: groupSettingsContext?.billToLocationType ?? 'BILL_1',
      surveyUrl:
        editingTemplate?.taskScheduleGroup.code === 'REQUEST_CELEBRANT_FEEDBACK'
          ? groupSettingsContext.surveyUrl
          : '',
    },
  })

  const actionValue: EventOption = watch('taskScheduleGroup')

  const shouldScheduleInputsShow = useMemo(() => {
    if (actionValue?.value === 'AUTOSHIP_AWARD_ORDER') {
      if (celebrationFrequency === 'DAILY_BATCH_MONTHLY') return false
    }
    return true
  }, [actionValue?.value, celebrationFrequency])

  const dateSuffix = useMemo(() => {
    const stDays = [1, 21, 31]
    const ndDays = [2, 22]
    const rdDays = [3, 23]
    if (stDays.includes(batchDay)) {
      return 'st'
    }
    if (ndDays.includes(batchDay)) {
      return 'nd'
    }
    if (rdDays.includes(batchDay)) {
      return 'rd'
    }
    return 'th'
  }, [batchDay])

  const onSubmit = handleSubmit(
    (data) => {
      if (editingTemplate && onEdit) {
        onEdit(data, editingTemplate)
      } else {
        onSave(data)
        reset()
      }
    },
    () => {
      setFormAlert({
        isOpen: true,
        type: 'error',
        message: 'There was an error in the form.',
      })
    }
  )

  const handleCancel = (e) => {
    e.preventDefault()
    reset()
    setFormAlert({ isOpen: false, type: '', message: '' })
    onCancel()
  }

  eventOptions.sort((a, b) => {
    const aFirstWord = a.label.split(' ')[0]
    const bFirstWord = b.label.split(' ')[0]
    return aFirstWord.localeCompare(bFirstWord)
  })
  const filteredEventOptions = eventOptions.filter(
    (event) =>
      !actionScheduleTemplates.some(
        (template) => template.taskScheduleGroup.code === event.value
      )
  )

  const actionsAffectedByPrivacySettings = [
    'REGISTER_INVITES',
    'REQUEST_MANAGER_PERSONAL_NOTE',
    'NOTIFY_PEERS_ANNIVERSARY_DAY',
    'REQUEST_PEERS_PERSONAL_NOTE',
  ]
  const shippingInputActions = [
    'AUTOSHIP_AWARD_ORDER',
    'REGISTER_INVITES',
    'REQUEST_MANAGER_PERSONAL_NOTE',
  ]

  const reminderInfoMessage = [
    'NOTIFY_CELEBRANT_INVITE_PEERS',
    'NOTIFY_CELEBRANT_ORDER_FROM_CATALOG',
  ]

  useEffect(() => {
    // Lock the offsetFrom if we don't have an action value because the API can't get a value yet
    if (!actionValue?.value) {
      setLockInputs({ when: false, offsetFrom: true, startOffset: false })
      return
    }

    // Reset all locks when an actionValue.value is selected so logic below doesn't cause any funny behavior
    setLockInputs({ when: false, offsetFrom: false, startOffset: false })

    switch (actionValue?.value) {
      case 'CREATE_CELEBRATION':
        setValue('when', 'before')
        setValue('offsetFrom', 'ANNIVERSARY_DATE')
        setLockInputs({ when: true, offsetFrom: true, startOffset: false })
        break
      case 'CLOSE_CELEBRATION':
        setValue('when', 'after')
        setValue('offsetFrom', 'CELEBRATION_DATE')
        setLockInputs({ when: true, offsetFrom: true, startOffset: false })
        break
      case 'DEPOSIT_POINTS':
      case 'DEPOSIT_ACCRUAL_POINTS':
        setValue('when', 'before')
        setValue('offsetFrom', 'CELEBRATION_DATE')
        setValue('startOffset', 1)
        setLockInputs({ when: true, offsetFrom: true, startOffset: true })
        break
      case 'AUTOSHIP_AWARD_ORDER':
        setValue('addInternationalShippingOffset', true)
        break
      default:
        setLockInputs({ when: false, offsetFrom: false, startOffset: false })
        break
    }
  }, [actionValue, setValue, groupSettingsContext])

  return (
    <FormControl
      data-testid="action-form"
      sx={{ m: 3, width: '100%' }}
      component="fieldset"
      variant="standard"
    >
      <FormGroup
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        }}
      >
        {formAlert.isOpen && (
          <Box
            sx={{
              width: '70%',
            }}
          >
            <Alert
              data-testid="action-form:error-alert"
              onClose={() =>
                setFormAlert({ isOpen: false, type: '', message: '' })
              }
              severity="error"
            >
              {formAlert.message || 'An error has occured. Please try again.'}
            </Alert>
          </Box>
        )}

        {!editingTemplate && (
          <ActionInput
            filteredEventOptions={filteredEventOptions}
            control={control}
            errors={errors}
          />
        )}

        {reminderInfoMessage.includes(actionValue?.value as string) && (
          <ActionFormMessages
            useBullets
            messages={[
              'Includes three reminder emails sent every seven days after the initial notification.',
              'Action is removed when privacy settings are turned on.',
            ]}
          />
        )}
        {actionsAffectedByPrivacySettings.includes(
          actionValue?.value as string
        ) && (
          <ActionFormMessages
            messages={[
              'Action is removed when privacy settings are turned on.',
            ]}
          />
        )}

        {shouldScheduleInputsShow && (
          <ScheduleInputs
            lockInputs={lockInputs}
            control={control}
            errors={errors}
            actionValue={actionValue?.value as TaskScheduleGroupCodeInput}
            celebrationLevelGroupId={groupSettingsContext?.id}
          />
        )}

        {actionValue?.value === 'AUTOSHIP_AWARD_ORDER' &&
          !shouldScheduleInputsShow && (
            <>
              <Typography variant="h4">Schedule</Typography>
              <Typography>
                {batchDay < 0
                  ? `${Math.abs(
                      batchDay
                    )} days before the beginning of the anniversary month`
                  : `${Math.abs(
                      batchDay
                    )}${dateSuffix} day of the month before the anniversary`}
              </Typography>
            </>
          )}

        {shippingInputActions.includes(actionValue?.value as string) && (
          <ShippingInputs control={control} actionValue={actionValue} />
        )}

        {(actionValue?.value === 'REQUEST_CELEBRANT_FEEDBACK' ||
          editingTemplate?.taskScheduleGroup.code ===
            'REQUEST_CELEBRANT_FEEDBACK') && (
          <SurveyUrlInput control={control} errors={errors} />
        )}
        <ActionButtons
          isEditMode={!!editingTemplate}
          onSubmit={onSubmit}
          onCancel={handleCancel}
          loading={loading}
        />
      </FormGroup>
    </FormControl>
  )
}

export default ActionForm
