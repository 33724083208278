import { GET_ALL_TASK_SCHEDULE_GROUPS } from '../graphql'
import { useQuery } from '@apollo/client'
import {
  TaskScheduleGroupsResponse,
  ReturnData,
} from '../models/YbGetAllTaskScheduleGroups'

export const useGetAllTaskScheduleGroups = (): ReturnData => {
  const { data, loading, error } = useQuery<TaskScheduleGroupsResponse>(
    GET_ALL_TASK_SCHEDULE_GROUPS
  )
  const taskScheduleGroups = data?.ybGetAllTaskScheduleGroups.taskScheduleGroups
  return { taskScheduleGroups, loading, error }
}
