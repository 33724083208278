import { useMutation } from '@apollo/client'
import { CelebrationLevelGroupConfig } from '../common/models/CelebrationLevelGroupConfig'
import { YbCelebrationLevelGroupConfigUpdateInput } from '../common/models/YbCelebrationLevelGroupConfigUpdateInput'
import {
  GET_PROGRAM_OFFSETS_BY_PROGRAM_ID,
  PATCH_CELEBRATION_CONFIG,
} from '../graphql'

const usePatchCelebrationConfig = () => {
  const [mutate, { loading, error }] = useMutation<
    { ybPatchCelebrationLevelGroupConfigs: CelebrationLevelGroupConfig },
    { input: YbCelebrationLevelGroupConfigUpdateInput }
  >(PATCH_CELEBRATION_CONFIG)

  const patchCelebrationConfig = async (
    input: YbCelebrationLevelGroupConfigUpdateInput
  ) => {
    await mutate({
      variables: { input },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_PROGRAM_OFFSETS_BY_PROGRAM_ID,
          variables: { programId: input.programId },
          errorPolicy: 'all',
        },
      ],
    })
  }

  return { patchCelebrationConfig, loading, error }
}

export default usePatchCelebrationConfig
