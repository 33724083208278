import { useQuery } from '@apollo/client'
import {
  OffsetFrom,
  YbGetValidOffsetFromValuesInput,
  YbGetValidOffsetFromValuesPayload,
} from '../common/models/YbGetValidOffsetFromValues'
import { GET_VALID_OFFSET_FROM_VALUES } from '../graphql'

interface Response {
  ybGetValidOffsetFromValues: {
    validOffsetsFrom: OffsetFrom[]
  }
}

const useGetValidOffsetFromValues = (
  input: YbGetValidOffsetFromValuesInput
): YbGetValidOffsetFromValuesPayload | undefined => {
  const { data } = useQuery<Response | undefined>(
    GET_VALID_OFFSET_FROM_VALUES,
    {
      variables: { input },
      errorPolicy: 'all',
      skip: !input.actionType,
    }
  )

  return {
    validOffsetsFrom: data?.ybGetValidOffsetFromValues.validOffsetsFrom || [],
  }
}

export default useGetValidOffsetFromValues
