import {
  Box,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@octanner/prism-core'
import React from 'react'
import { Control, Controller, FieldErrorsImpl } from 'react-hook-form'
import { FormData } from './ActionForm'
import useGetValidOffsetFromValues from '../../hooks/useGetValidOffsetFromValues'
import { TaskScheduleGroupCodeInput } from 'library/src/models/ActionScheduleTemplate'
import { OffsetFrom } from 'library/src/common/models/YbGetValidOffsetFromValues'

interface Props {
  lockInputs: {
    when: boolean
    offsetFrom: boolean
    startOffset: boolean
  }
  control: Control<FormData>
  errors: Partial<FieldErrorsImpl<FormData>>
  actionValue: TaskScheduleGroupCodeInput | null
  celebrationLevelGroupId: string
}

const ScheduleInputs = ({
  lockInputs,
  control,
  errors,
  actionValue,
  celebrationLevelGroupId,
}: Props) => {
  const possibleOffsetFromValues = [
    { value: 'ANNIVERSARY_DATE', label: 'Anniversary Date' },
    { value: 'CELEBRATION_DATE', label: 'Celebration Date' },
    { value: 'MONTHLY_BATCH_DATE', label: 'Monthly Batch Date' },
  ]

  const offsetPayload = useGetValidOffsetFromValues({
    celebrationLevelGroupUuid: celebrationLevelGroupId,
    actionType: actionValue,
  })

  const filteredValues = possibleOffsetFromValues.filter((item) =>
    offsetPayload.validOffsetsFrom.includes(item.value as OffsetFrom)
  )

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '70%',
      }}
    >
      <Typography variant="h3">Schedule</Typography>
      <Box
        sx={{
          display: 'flex',
          gap: '24px',
        }}
      >
        <Controller
          name="startOffset"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              data-testid="action-form:start-offset"
              label="Days"
              type="number"
              InputProps={{
                readOnly: lockInputs.startOffset,
              }}
            />
          )}
        />
        <Controller
          name="when"
          control={control}
          rules={{ required: 'This field is required' }}
          render={({ field }) => (
            <Select
              {...field}
              data-testid="action-form:when-input"
              height="tall"
              error={!!errors.when}
              helperText={errors.when?.message}
              label="When"
              inputProps={{
                readOnly: lockInputs.when,
              }}
            >
              <MenuItem value={'before'}>Before</MenuItem>
              <MenuItem value={'after'}>After</MenuItem>
            </Select>
          )}
        />
        <Controller
          name="offsetFrom"
          control={control}
          rules={{ required: 'This field is required' }}
          render={({ field }) => (
            <Select
              {...field}
              data-testid="action-form:offset-from"
              height="tall"
              defaultValue=""
              error={!!errors.offsetFrom}
              helperText={errors.offsetFrom?.message}
              label="Event"
              inputProps={{
                readOnly:
                  lockInputs.offsetFrom ||
                  offsetPayload.validOffsetsFrom.length === 0,
              }}
            >
              {filteredValues.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </Box>
    </Box>
  )
}

export default ScheduleInputs
