import {
  AdapterLuxon,
  Box,
  Button,
  Card,
  DatePicker,
  Loader,
  TextField,
  Timeline,
  ToggleButton,
} from '@octanner/prism-core'
import React, { useMemo, useState } from 'react'
import { useGroupSettings } from '../../contexts/GroupSettingsContext'
import { YbActionScheduleTemplateRepresentation } from '../../models/ActionScheduleTemplate'
import TimelineCard from '../TimelineCard'
import { transformDataToEvents } from './utils'

interface Props {
  loading: boolean
  actionScheduleTemplates: YbActionScheduleTemplateRepresentation[]
  celebrationLevelGroupUuid?: string
  showToggleScheduleBtn?: boolean
  editingTemplate: YbActionScheduleTemplateRepresentation | null
  setEditingTemplate: (template: YbActionScheduleTemplateRepresentation) => void
  onDeleteActionTemplate: ({ actionScheduleTemplateUuid, astName }) => void
  editForm: () => JSX.Element
}

const CelebrationGroupSchedule = ({
  loading,
  actionScheduleTemplates,
  celebrationLevelGroupUuid,
  showToggleScheduleBtn = false,
  editingTemplate,
  setEditingTemplate,
  onDeleteActionTemplate,
  editForm,
}: Props) => {
  const [showSchedule, setShowSchedule] = useState(
    celebrationLevelGroupUuid && showToggleScheduleBtn ? false : true
  )
  const [scheduleView, setScheduleView] = useState<'offsets' | 'preview'>(
    'offsets'
  )
  const [selectedDateObj, setSelectedDateObj] = useState({
    date: new Date(),
    lastValidDate: new Date(),
    isValid: true,
  })
  const groupSettingsContext = useGroupSettings()
  const isEditMode = useMemo(
    () => !!groupSettingsContext?.id,
    [groupSettingsContext?.id]
  )

  const handleScheduleViewChange = (_, newAlignment) => {
    setScheduleView(newAlignment)
  }

  const handleDateChange = (date) => {
    setSelectedDateObj({
      date: date,
      lastValidDate: date?.invalid ? selectedDateObj.lastValidDate : date,
      isValid: date?.invalid ? false : true,
    })
  }

  const eventParams = {
    actionScheduleTemplates,
    scheduleObject: {
      scheduleType: scheduleView,
      scheduleDate: selectedDateObj.isValid
        ? selectedDateObj.date
        : selectedDateObj.lastValidDate,
    },
    editingTemplate,
    onDeleteClick: onDeleteActionTemplate,
    setEditingTemplate,
    editForm,
    isEditMode,
  }

  const timelineRenderDecider = {
    offsetFromAnniversary: actionScheduleTemplates?.some(
      (template) => template.offsetFrom === 'ANNIVERSARY_DATE'
    ),
    offsetFromCelebration: actionScheduleTemplates?.some(
      (template) => template.offsetFrom === 'CELEBRATION_DATE'
    ),
    offsetFromBatchMonthly: actionScheduleTemplates?.some(
      (template) => template.offsetFrom === 'MONTHLY_BATCH_DATE'
    ),
  }

  return (
    <>
      {actionScheduleTemplates?.length > 0 &&
        celebrationLevelGroupUuid &&
        showToggleScheduleBtn && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              marginTop: '10px',
            }}
          >
            <Button
              data-testid="celebration-group:schedule-toggle-btn"
              variant="text"
              onClick={() => {
                setShowSchedule((prev) => !prev)
              }}
            >
              {showSchedule ? 'Hide' : 'Show'} schedule
            </Button>
          </Box>
        )}

      {loading && <Loader />}

      {showSchedule && !loading && (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginY: '10px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 4,
              }}
            >
              {celebrationLevelGroupUuid && (
                <Box>
                  <ToggleButton
                    options={[
                      {
                        value: 'offsets',
                        label: 'Offsets',
                        icon: '',
                      },
                      {
                        value: 'preview',
                        label: 'Preview',
                        icon: '',
                      },
                    ]}
                    value={scheduleView}
                    onChange={handleScheduleViewChange}
                  />
                </Box>
              )}
              {scheduleView === 'preview' && (
                <DatePicker
                  label="Anniversary Date"
                  dateAdapter={AdapterLuxon}
                  value={selectedDateObj.date}
                  onChange={handleDateChange}
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      helperText={
                        !selectedDateObj.isValid
                          ? 'Enter date in DD MMM YYYY format'
                          : ''
                      }
                      error={!selectedDateObj.isValid}
                    />
                  )}
                />
              )}
            </Box>
          </Box>

          {scheduleView === 'preview' ? (
            <Card
              sx={{ padding: '20px' }}
              data-testid="celebration-group:timeline-card:preview"
            >
              <Timeline
                events={transformDataToEvents({ ...eventParams })}
                variant="complex"
              />
            </Card>
          ) : (
            <>
              {timelineRenderDecider.offsetFromAnniversary && (
                <TimelineCard
                  eventParams={eventParams}
                  headerText="Anniversary Date Offsets"
                  testIdSuffix="anniversaryDate"
                  offsetType="ANNIVERSARY_DATE"
                />
              )}

              {timelineRenderDecider.offsetFromBatchMonthly && (
                <TimelineCard
                  eventParams={eventParams}
                  headerText="Batched Monthly Date Offsets"
                  testIdSuffix="monthlyBatchDate"
                  offsetType="MONTHLY_BATCH_DATE"
                />
              )}

              {timelineRenderDecider.offsetFromCelebration && (
                <TimelineCard
                  eventParams={eventParams}
                  headerText="Celebration Date Offsets"
                  testIdSuffix="celebrationDate"
                  offsetType="CELEBRATION_DATE"
                />
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

export default CelebrationGroupSchedule
