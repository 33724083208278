import { Box, Card, Timeline, Typography } from '@octanner/prism-core'
import { tannerGray } from '@octanner/prism-core/ThemeProvider/colors'
import React from 'react'
import { OffsetFrom } from '../common/models/YbGetValidOffsetFromValues'
import { YbActionScheduleTemplateRepresentation } from '../models/ActionScheduleTemplate'
import { transformDataToEvents } from './CelebrationGroupSchedule/utils'

interface Props {
  eventParams: {
    actionScheduleTemplates: YbActionScheduleTemplateRepresentation[]
    scheduleObject: {
      scheduleType: 'offsets' | 'preview'
      scheduleDate: Date
    }
    editingTemplate: YbActionScheduleTemplateRepresentation
    onDeleteClick: ({ actionScheduleTemplateUuid, astName }) => void
    setEditingTemplate: (
      template: YbActionScheduleTemplateRepresentation
    ) => void
    editForm: () => JSX.Element
    isEditMode: boolean
  }
  headerText: string
  testIdSuffix: string
  offsetType: OffsetFrom
}

export default function TimelineCard({
  eventParams,
  headerText,
  testIdSuffix,
  offsetType,
}: Props) {
  return (
    <Box sx={{ marginBottom: '24px' }}>
      <Box
        sx={{
          padding: '8px 16px',
          borderBottom: `1px solid ${tannerGray[200]}`,
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
          background: tannerGray[50],
          boxShadow:
            '0px 0px 2px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.17), 0px 0px 3px rgba(0, 0, 0, 0.2)',
        }}
      >
        <Typography variant="h3">{headerText}</Typography>
      </Box>
      <Card
        sx={{
          paddingX: '20px',
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }}
        data-testid={`celebration-group:timeline-card:${testIdSuffix}`}
      >
        <Timeline
          events={transformDataToEvents({
            offsetType: offsetType,
            ...eventParams,
          })}
          variant="complex"
        />
      </Card>
    </Box>
  )
}
